.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.flex-fullHeight-container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.no-overflowx {
  overflow-x: hidden;
}

.no-overflowy {
  overflow-y: hidden;
}

html,
body,
#root {
  height: 100%;
}

.bm-settingsMenu {
  width: 360px;
}

.header-button {
  border: 0;
  width: 93%;
  padding-top: 8px;
  padding-bottom: 8px;
  background: transparent;
  margin-left: -0.2em;
  line-height: normal;
  display: flex;
  flex-direction: row;
}

#root {
  overflow-y: auto;
  overflow-x: auto;
}

.MuiInputBase-input {
  text-rendering: geometricPrecision;
}
