body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Montserrat", "Kanit",
    "Ubuntu", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.c3-opaque-circle .c3-circle {
  opacity: 1 !important;
}

.c3-dark-mode .c3-text {
  fill: #f1f2f6;
}

.c3-dark-mode .c3-axis {
  fill: #f1f2f6;
}

.c3-dark-mode .domain {
  stroke: #f1f2f6;
}

.c3-dark-mode .c3-legend-item {
  fill: #f1f2f6;
}

.c3-dark-mode .tick line {
  stroke: #f1f2f6;
}
